import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import decodeToken from 'auth/DecodeToken';
import { get } from 'lodash';

const Routes = () => {
  const profile = decodeToken();
  const schoolId = profile?.id_school;

  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        {protectedRoutes
          .filter((nav) => get(nav, 'profile', []).includes(get(profile, 'rol', '')))
          .map((route, index) => {
            return (
              <Route
                key={route.key + index}
                path={route.path}
                element={
                  <AppRoute routeKey={route.key} component={route.component} {...route.meta} />
                }
              />
            );
          })}
        <Route
          path="*"
          element={
            <Navigate
              to={schoolId ? `/app/schools/edit/${schoolId}/1` : '/app/users/list'}
              replace
            />
          }
        />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute routeKey={route.key} component={route.component} {...route.meta} />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
