import fetch from 'auth/FetchInterceptor';

const AuthService = {};

AuthService.signIn = function (data) {
  return fetch({
    url: '/auth/admin/login',
    method: 'post',
    data: data
  });
};

AuthService.signUp = function (data) {
  return fetch({
    url: '/auth/sign-up',
    method: 'post',
    data: data
  });
};

export default AuthService;
