import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTH_PREFIX_PATH, UNAUTHENTICATED_ENTRY, REDIRECT_URL_KEY } from 'configs/AppConfig';
import { useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_LIBRARIES } from 'constants/ApiConstant';
import { GOOGLE_MAPS_API_KEY } from 'configs/AppConfig';
import decodeToken from 'auth/DecodeToken';

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const profile = decodeToken();
  const navigate = useNavigate();
  const schoolId = profile?.id_school;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES
  });

  useEffect(() => {
    if (token && schoolId) {
      if (
        !location.pathname.includes(`/app/schools/edit/${schoolId}`) ||
        !location.pathname.includes(`/school/${schoolId}`)
      ) {
        navigate(`/app/schools/edit/${schoolId}/1`);
      }
    }
  }, []);

  if (!isLoaded) return <></>;

  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
