import jwtDecode from 'jwt-decode';

const decodeToken = () => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (err) {
      console.log(err);
    }
  }
  return '';
};

export default decodeToken;
