import axios from 'axios';
import history from '../history';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification, message } from 'antd';
import { get } from 'lodash';
import { API_BASE_URL } from 'configs/AppConfig';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
});

// Config
const ENTRY_ROUTE = 'auth/login';
const TOKEN_PAYLOAD_KEY = 'Authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem('auth_token');
    // let timeZone = String(Intl.DateTimeFormat().resolvedOptions().timeZone);
    config.params = { ...config.params /*timezone: timeZone*/ };

    if (jwtToken && config.url !== '/auth/admin/login' && config.url !== '/auth/sign-up') {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (
      !jwtToken &&
      !config.headers[PUBLIC_REQUEST_KEY] &&
      config.url !== '/auth/admin/login' &&
      config.url !== '/auth/sign-up'
    ) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    console.log(error);
    // Do something with request error here
    notification.error({
      message: 'Error'
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: ''
    };

    // Remove token and redirect
    if (error.response.status === 403 || error.response.status === 401) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('d');
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    // Remove token and redirect
    if (error.response.status === 422) {
      error.response.status = 200;
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('d');
      setTimeout(() => {
        history.push(ENTRY_ROUTE);
        window.location.reload();
      }, 3000);
    }

    if (error.response.status === 404) {
      notificationParam.message = error.response.message;
    }

    if (error.response.status === 500) {
      const errorText = get(error, 'response.data.message', 'Internal Server Error');
      message.error(errorText);
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    if (notificationParam.message !== '') {
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
